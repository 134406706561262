import { atom, selector } from 'recoil';
import API, { AutoKickDetails } from '../API';
import { IslandListingDetails } from '../model/IslandListingDetails';
import { IslandVisitorInfo } from '../model/IslandListingVisitors';

/**
 * Determines whether the TE Forger server is online or not.
 */
export const isTEServerOnlineState = selector<boolean>({
	key: 'isTEServerOnlineState',
	get: async () => {
		return await API.fetchServerStatus().catch(() => {}) ?? false;
	}
});

/**
 * The current session's turnip code.
 */
export const turnipCodeState = selector<string | undefined>({
	key: 'turnipCodeState',
	get: async () => {
		return await API.fetchTurnipCode().catch(() => {}) ?? undefined;
	}
});

/**
 * Forces the `listingDetailsState` to update when this is arbitrarily changed, as this is a dependency to the `listingDetailsState` selector.
 */
export const listingDetailsStateInvalidator = atom<number>({
	key: 'listingDetailsStateInvalidatorState',
	default: 0
});

/**
 * The current island listing's details.
 */
export const listingDetailsState = selector<IslandListingDetails | undefined>({
	key: 'listingDetailsState',
	get: async ({get}) => {
		const isListingOpen: boolean = get(listingOpenState);

		if (!isListingOpen)
			return undefined;

		// Declare `listingDetailsStateInvalidatorState` as a dependency. When it changes, listing details are re-fetched from the server.
		get(listingDetailsStateInvalidator);
		return await API.fetchListingDetails().catch(() => {}) ?? undefined;
	}
});

/**
 * The visitor info list.
 */
export const visitorInfoState = selector<IslandVisitorInfo | undefined>({
	key: 'visitorInfoState',
	get: async () => {
		return await API.fetchVisitors().catch(() => {}) ?? undefined;
	}
});

/**
 * Returns `true` if the listing is currently open in TE, `false` otherwise.
 */
export const listingOpenState = selector<boolean>({
	key: 'listingOpenState',
	get: async () => {
		return await API.fetchTurnipCode() !== null;
	}
});

/**
 * A state used as a dependency by the `autoKickDetailsState` selector. When this is arbitrarily updated, the `autoKickDetailsState` selector gets updated too.
 */
export const autoKickInvalidatorState = atom<number>({
	key: 'autoKickInvalidatorState',
	default: 0
});

/**
 * The auto-kick details object.
 */
export const autoKickDetailsState = selector<AutoKickDetails | undefined>({
	key: 'autoKickDetailsState',
	get: async ({get}) => {
		// Mark `autoKickInvalidatorState` as a dependency.
		get(autoKickInvalidatorState);

		const autoKickDetailsResponse: AutoKickDetails | Error = await API.fetchAutoKickDetails().catch(ex => ex);

		if (autoKickDetailsResponse instanceof Error)
			return undefined;

		return autoKickDetailsResponse;
	}
});
import { ManualOverrideSection } from "./sections/ManualOverrideSection";
import './TEAdminPage.scss';
import { Suspense } from "react";
import { ServerStatusSection } from "./sections/ServerStatusSection";
import { ListingInfoSection } from "./sections/ListingInfoSection";
import { ListingCommandsSection } from "./sections/ListingCommandsSection";
import { UserGroup } from "../../../services/AuthenticationAPI";
import { useRecoilValue } from "recoil";
import { BASE_URL } from "../../../services/config";
import { userGroupState } from "../state/te-admin.recoil";

export function TEAdminPage(): JSX.Element {
	const userGroup: UserGroup | undefined = useRecoilValue(userGroupState);
	
	if (!userGroup) {
		window.location.href = BASE_URL;
		return <></>;
	}

	if (!userGroup.isMod) {
		window.location.href = BASE_URL;
		return <></>;
	}
		
	return (
		userGroup.isMod ? <div className="page page-TEAdmin">
			<Suspense>
				<ServerStatusSection />
			</Suspense>

			<Suspense>
				<ListingInfoSection />
			</Suspense>

			<Suspense>
				<ListingCommandsSection />
			</Suspense>

			<ManualOverrideSection />
		</div> : <></>
	);
};
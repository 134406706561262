import React, { ReactNode } from "react";
import { NavLink } from 'react-router-dom';

export interface NavigationBarItemProps {
	url: string;
	children: React.ReactNode;
};

class NavigationBarItem extends React.Component<NavigationBarItemProps> {
	public constructor(props: NavigationBarItemProps) {
		super(props);
	}

	public render(): ReactNode {
		return (
			<NavLink className={({ isActive }) => `navLink button ${isActive ? 'selected' : ''}`} to={this.props.url}>
				{this.props.children}
			</NavLink>
		);
	}
}

export default NavigationBarItem;
import React, { useState } from "react";
import API, { AutoKickDetails } from "../API";
import { useRecoilValue, useRecoilState } from 'recoil';
import { autoKickDetailsState, autoKickInvalidatorState, listingDetailsState, listingOpenState } from "../state/recoil";
import { IslandListingDetails } from "../model/IslandListingDetails";

export function ListingCommandsSection(): JSX.Element {
	const isListingOpen: boolean 											= useRecoilValue(listingOpenState);
	const listingDetails: IslandListingDetails | undefined 					= useRecoilValue(listingDetailsState);
	const [listingCommandsSectionVisible, setListingCommandsSectionVisible] = useState<boolean>(true);
	const autoKickDetails: AutoKickDetails | undefined 						= useRecoilValue(autoKickDetailsState);
	const [autoKickInvalidatorValue, setAutoKickInvalidatorValue] 			= useRecoilState(autoKickInvalidatorState);
	
	const [autoKickThreshold, setAutoKickThreshold] = useState<string>(autoKickDetails?.threshold.toString() ?? '');
	const [maxLength, setMaxLength] 				= useState<string>(listingDetails?.maxQueue.toString() ?? '');
	const [maxVisitors, setMaxVisitors] 			= useState<string>(listingDetails?.visitorLimit.toString() ?? '');
	const [turnipPrice, setTurnipPrice] 			= useState<string>(listingDetails?.turnipPrice.toString() ?? '');
	const [description, setDescription] 			= useState<string>(listingDetails?.description ?? '');
	const [dodoCode, setDodoCode] 					= useState<string>(listingDetails?.dodoCode ?? '');
	const [message, setMessage] 					= useState<string>('Hello everyone! The queue here will be closed in about 10 minutes. You may join our queue on Twitch which will be locked shortly after but grants you a spot on the island. See you tomorrow, same time! Have a lovely night <3');

	const btnOpenIslandClicked = async (_: React.MouseEvent<HTMLElement>) => {
		let dodoCode = prompt('Insert Dodo code', '12345');

		if (!dodoCode)
			return alert(`Please insert a Dodo code.`);

		const status = await API.openListing(dodoCode).catch(ex => ex);

		if (status instanceof Error)
			return alert(`There was an error while opening the island listing: ${status.message}`);

		if (status === true) {
			alert(`The island listing was opened correctly.`);
			window.location.reload();
		} else
			alert(`The island listing could not be opened.`);
	};

	const btnDestroyIsland = async (_: React.MouseEvent<HTMLElement>) => {
		if (!window.confirm(`Are you sure?`))
			return;

		const result = await API.deleteIslandListing().catch(ex => ex);

		if (result instanceof Error)
			return alert(result.message);
		
		if (result === true) {
			alert('Island deleted successfully.');
			window.location.reload();
		} else
			alert('The island listing could not be deleted.');
	};

	const btnLockClicked = async (_: React.MouseEvent<HTMLElement>) => {
		if (!listingDetails)
			return;

		const result = await API.setIslandLockedState(!listingDetails.locked).catch(ex => ex);

		if (result instanceof Error)
			return alert(result.message);

		if (result === true)
			alert(`The island listing was ${(!listingDetails.locked) ? 'locked' : 'unlocked'} successfully.`);
		else
			alert('There was an error while toggling lock for the island listing.');

		window.location.reload();
	};

	const btnPrivateClicked = async (_: React.MouseEvent<HTMLElement>) => {
		const result = await API.setIslandPrivateState(!listingDetails?.private).catch(ex => ex);

		if (result instanceof Error)
			return alert(result.message);
		
		alert(`Island was set ${!listingDetails?.private ? 'private' : 'public'} successfully.`);

		window.location.reload();
	};

	const btnToggleAutoKickClicked = async (_: React.MouseEvent<HTMLElement>) => {
		if (!autoKickDetails)
			return alert('Auto-Kick could not be toggled, autoKickDetails is not defined.');
		
		const result = await API.setAutoKickEnabled(!autoKickDetails.enabled).catch(ex => ex);

		if (result instanceof Error)
			return alert(result.message);

		setAutoKickInvalidatorValue(autoKickInvalidatorValue + 1);

		alert(`Auto-Kick ${!autoKickDetails.enabled ? 'enabled' : 'disabled'} successfully.`);
	};

	const btnUpdateClicked = async (e: React.MouseEvent<HTMLElement>) => {
		let modified: boolean = false;

		if (parseInt(autoKickThreshold) !== autoKickDetails?.threshold) {
			const response = await API.updateAutoKickThreshold(parseInt(autoKickThreshold));

			if (!response)
				return alert(`There was an error while updating the auto-kick threshold.`);

			modified = true;
		}

		const targets: { 
			stateAccessor: () => any, 
			listingName: string, 
			hook: ((fieldValue: any) => Promise<boolean>)
		}[] = [
			{ stateAccessor: () => maxLength, listingName: 'maxQueue', hook: fieldValue => API.updateMaxLength(fieldValue) },
			{ stateAccessor: () => maxVisitors, listingName: 'visitorLimit', hook: fieldValue => API.updateMaxVisitors(fieldValue) },
			{ stateAccessor: () => turnipPrice, listingName: 'turnipPrice', hook: fieldValue => API.updateTurnipPrice(fieldValue) },
			{ stateAccessor: () => description, listingName: 'description', hook: fieldValue => API.updateDescription(fieldValue) },
			{ stateAccessor: () => dodoCode, listingName: 'dodoCode', hook: fieldValue => API.updateDodoCode(fieldValue) }
		];

		const requestPromises: Promise<boolean>[] = [];

		targets.forEach(target => {
			const listingDetailsValue: unknown = listingDetails?.[target.listingName];

			const isDifferent = typeof listingDetailsValue === 'number' ? (parseInt(target.stateAccessor()) !== listingDetails?.[target.listingName]) : (target.stateAccessor() !== listingDetails?.[target.listingName]);
			
			if (isDifferent) {
				requestPromises.push(target.hook(target.stateAccessor()).catch(ex => { console.log(ex); return false }));
				modified = true;
			}
		});

		if (!modified)
			return alert('Nothing changed.');

		const awaitedPromises = await Promise.all(requestPromises);

		if (awaitedPromises.includes(false))
			alert(`There was an error while updating the island listing.`);
		else
			alert(`Island listing was updated successfully.`);

		window.location.reload();
	};

	const btnSendMessageClicked = async (e: React.MouseEvent<HTMLElement>) => {
		const result = await API.sendMessage(message);

		if (result)
			alert('Message sent successfully.');
		else
			alert('Could not send message.');
	};

	return (
		<div className={`listingCommands ${listingCommandsSectionVisible ? 'listingCommandsVisible' : 'listingCommandsHidden'}`}>
        	<h1 onClick={() => (setListingCommandsSectionVisible(!listingCommandsSectionVisible))}>Commands</h1>
			
			<div className="commandsWrapper flex-row">
				<div className={!isListingOpen ? 'button' : 'button disabled'} id="btnOpenIsland" onClick={btnOpenIslandClicked}>Open Island</div>
				<div className={isListingOpen ? 'button' : 'button disabled'} id="btnDestroyIsland" onClick={btnDestroyIsland}>Destroy Island</div>
				<div className={isListingOpen ? 'button' : 'button disabled'} id="btnLock" onClick={btnLockClicked}>{listingDetails?.locked ? 'Unlock' : 'Lock'}</div>
				<div className={isListingOpen ? 'button' : 'button disabled'} id="btnPrivate" onClick={btnPrivateClicked}>{listingDetails?.private ? 'Set Public' : 'Set Private'}</div>
				<div className={isListingOpen ? 'button' : 'button disabled'} id="btnToggleAutoKick" onClick={btnToggleAutoKickClicked}>{autoKickDetails?.enabled ? 'Disable Auto-Kick' : 'Enable Auto-Kick'}</div>
			</div>

			<div className="commandPairsGrid">
				<div className="commandPairRow flex-row">
					<label>Auto-Kick Threshold:</label>
					<input id="inputAutoKick" type="text" value={autoKickThreshold} onChange={e => setAutoKickThreshold(e.target.value)} disabled={!isListingOpen} />
				</div>
				<div className="commandPairRow flex-row">
					<label>Max Length:</label>
					<input id="inputMaxLength" type="text" value={maxLength} onChange={e => setMaxLength(e.target.value)} disabled={!isListingOpen} />
				</div>
				<div className="commandPairRow flex-row">
					<label>Max Visitors:</label>
					<input id="inputMaxVisitors" type="text" value={maxVisitors} onChange={e => setMaxVisitors(e.target.value)} disabled={!isListingOpen} />
				</div>
				<div className="commandPairRow flex-row">
					<label>Turnips Price:</label>
					<input id="inputTurnipPrice" type="text" value={turnipPrice} onChange={e => setTurnipPrice(e.target.value)} disabled={!isListingOpen} />
				</div>
				<div className="commandPairRow flex-row">
					<label>Description:</label>
					<textarea id="inputDescription" value={description} onChange={e => setDescription(e.target.value)} disabled={!isListingOpen} />
				</div>
				<div className="commandPairRow flex-row">
					<label>Dodo Code:</label>
					<input id="inputDodoCode" type="text" value={dodoCode} onChange={e => setDodoCode(e.target.value)} disabled={!isListingOpen} />
				</div>
			</div>

			<div className={isListingOpen ? 'button' : 'button disabled'} id="btnUpdate" onClick={btnUpdateClicked}>Update</div>
			
			<div className="sendMessage">
				<h3>Send Message</h3>
				<textarea id="messageText" value={message} onChange={e => setMessage(e.target.value)} />
				<div className={isListingOpen ? 'button' : 'button disabled'} id="btnSendMessage" onClick={btnSendMessageClicked}>Send Message</div>
			</div>
      </div>
	);
}
import React from "react";

export function Bauble(props: {
	status: 'on' | 'off' | 'inactive';
} & React.PropsWithChildren): JSX.Element {
	let baubleStatusClass: string;

	switch(props.status) {
		case 'on':
			baubleStatusClass = 'bauble-on';
			break;
		case 'off':
			baubleStatusClass = 'bauble-off';
			break;
		case 'inactive':
			baubleStatusClass = 'bauble-inactive';
	}

	return (
		<div className={`bauble ${baubleStatusClass}`}>{props.children}</div>
	);
}
import React from "react";
import { ReactNode } from "react";
import { SIGN_IN_WITH_TWITCH_URI } from "../../services/config";

class Home extends React.Component {
	public constructor(props: any) {
		super(props);

		this.signInWithTwitchDidTap = this.signInWithTwitchDidTap.bind(this);
	}

	public signInWithTwitchDidTap(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {		
		window.location.href = SIGN_IN_WITH_TWITCH_URI + `&state=${encodeURI(window.location.origin + '/redirect')}`;
	}

	public render(): ReactNode {
		return (
			<div className="pageHome">
				<button onClick={this.signInWithTwitchDidTap}>Sign-in with Twitch</button>
			</div>
		);
	}
}

export default Home;
import { PASSPORT_BASE_URL } from "./config";

class API {
	public async fetchUserGroup(): Promise<UserGroup | undefined> {
		const response = await fetch(`${PASSPORT_BASE_URL}/userGroup`, {
			method: 'GET',
			credentials: 'include'
		});

		if (response === undefined)
			return undefined;

		const jsonResponse = await response.json() as UserGroup;
		return jsonResponse;
	}

	public async fetchAuthStatus(): Promise<AuthenticationStatus> {		
		const response = await fetch(`${PASSPORT_BASE_URL}/authStatus`, {
			method: 'GET',
			credentials: 'include'
		});

		const jsonResponse = await response.json();
		return jsonResponse;
	}
}

/**
 * Describes a user's UserGroup.
 */
export interface UserGroup {
	isGuest: boolean;
	isMod: boolean;
	isSub: string | boolean;
};

/**
 * Describes the /authStatus response.
 */
 export interface AuthenticationStatus {
	authenticationStatus: 'authenticated' | 'unauthenticated' | 'expired';
};

export default new API();
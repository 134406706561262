import { useRecoilValue } from 'recoil';
import { UserGroup } from '../../services/AuthenticationAPI';
import { userGroupState } from './state/te-admin.recoil';

import './AdminPage.scss';
import { BASE_URL } from '../../services/config';

export function AdminPage() {
	const userGroup: UserGroup | undefined = useRecoilValue(userGroupState);
	
	if (!userGroup) {
		window.location.href = BASE_URL;
		return <></>;
	}

	if (!userGroup.isMod) {
		window.location.href = BASE_URL;
		return <></>;
	}

	return (
		userGroup.isMod ? <div>
			<button>
				test API
			</button>
		</div> : <></>
	);
};

export default AdminPage;
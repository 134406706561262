import { useRecoilValue } from 'recoil';
import { isTEServerOnlineState } from "../state/recoil";

export function ServerStatusSection(): JSX.Element {
	const isTEServerOnline = useRecoilValue(isTEServerOnlineState);

	return (
		<div className={`serverStatus${isTEServerOnline ? ' online' : ' offline'}`}>
			<h1>TE Forger Server Status</h1>
			<h2>{isTEServerOnline ? 'Online' : 'Offline'}</h2>
		</div>
	);
}
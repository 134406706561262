import React from "react";
import { ReactNode } from "react";

class Redirect extends React.Component {
	private firstDidMount: boolean = true;

	public constructor(props: any) {
		super(props);
	}

	public componentDidMount() {
		if (!this.firstDidMount)
			return;

		this.firstDidMount = false;
	}

	public render(): ReactNode {
		return (
			<div className="pageRedirect">
				<h1>Redirect</h1>
			</div>
		);
	}
}

export default Redirect;
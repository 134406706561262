import React, { ReactNode } from "react";
import './NavigationBar.scss';

class NavigationBar extends React.Component<{ children?: React.ReactNode }> {
	public constructor(props: any) {
		super(props);
	}

	public render(): ReactNode {
		return (
			<div className="navBar">
				{ this.props.children }
			</div>
		);
	}
}

export default NavigationBar;
import React from "react";
import { ReactNode } from "react";
import { NavLink } from 'react-router-dom';

class NotFoundPage extends React.Component {
	public constructor(props: any) {
		super(props);
	}

	public render(): ReactNode {
		return (
			<div className="page-notFound">
				<h1>Not Found.</h1>
				<NavLink to="/">Home</NavLink>
			</div>
		);
	}
}

export default NotFoundPage;
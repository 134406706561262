import { IslandVisitorInfo } from "./model/IslandListingVisitors";

class API {
	private readonly basePath: string = 'https://te-forger.andreams.me/api';

	/**
	 * Makes an HTTP request to the supplied API endpoint. Throws an `Error` if the request fails.
	 * @param method The HTTP request method to use for the request.
	 * @param endpoint The endpoint to call and retrieve data from.
	 * @param query The URL search parameters in the form `<key>=<val>`, that will be URL encoded.
	 */
	private async request<T>(method: string, endpoint: string, query?: string): Promise<T> {
		if (query?.startsWith('?'))
			query = query.slice(1);

		const queryString = query ? ('?' + query) : '';

		const response: Response | Error = await fetch(`${this.basePath}/${endpoint}${queryString}`, {
			method: method
		}).catch((err: Error) => {
			return err;
		});

		console.debug(`[${method.toUpperCase()}] ${this.basePath}/${endpoint}${queryString}`);

		const errorMessageBase: string = `[${method.toUpperCase()}] ${this.basePath}/${endpoint}${queryString} -`;

		if (!(response instanceof Response))
			throw new Error(`${errorMessageBase} Request failed, fetch rejected: ${response.message}`);

		if (!response.ok) {
			const body: string | undefined = response.bodyUsed ? await response.text() : undefined;

			throw new Error(`${errorMessageBase} Request failed, HTTP status not 'ok': ${response.status}, response status text: ${response.statusText}. Response carried the following payload (undefined if none): ${body}`);
		}

		const body: string | void = await response.text().catch(() => {});

		if ((!body && body !== null))
			throw new Error(`${errorMessageBase} Request failed, could not retrieve response body.`);

		let json: T;

		try {
			json = JSON.parse(body as string);
		} catch {
			throw new Error(`${errorMessageBase} Request failed, could not JSON parse response body. Body: ${body}`);
		}

		return json;
	}

	/**
	 * Makes an HTTP GET request to the supplied API endpoint using the provided query string.
	 * @rejects `Error` if the request fails. The error message is descriptive.
	 * @param endpoint The API endpoint to call
	 * @param query The non-URL encoded query string in `<key>=<value>&[...]` format, without the `?` at the beginning.
	 */
	private async get<T>(endpoint: string, query?: string): Promise<T> {
		return await this.request('GET', endpoint, query);
	}

	/**
	 * Makes an HTTP POST request to the supplied API endpoint using the provided query string.
	 * @rejects `Error` if the request fails. The error message is descriptive.
	 * @param endpoint The API endpoint to call
	 * @param query The non-URL encoded query string in `<key>=<value>&[...]` format, without the `?` at the beginning.
	 */
	private async post<T>(endpoint: string, query?: string): Promise<T> {
		return await this.request('POST', endpoint, query);
	}

	/**
	 * Makes an HTTP PATCH request to the supplied API endpoint using the provided query string.
	 * @rejects `Error` if the request fails. The error message is descriptive.
	 * @param endpoint The API endpoint to call
	 * @param query The non-URL encoded query string in `<key>=<value>&[...]` format, without the `?` at the beginning.
	 */
	private async patch<T>(endpoint: string, query?: string): Promise<T> {
		return await this.request('PATCH', endpoint, query);
	}

	/**
	 * Makes an HTTP DELETE request to the supplied API endpoint using the provided query string.
	 * @rejects `Error` if the request fails. The error message is descriptive.
	 * @param endpoint The API endpoint to call
	 * @param query The non-URL encoded query string in `<key>=<value>&[...]` format, without the `?` at the beginning.
	 */
	private async delete<T>(endpoint: string, query?: string): Promise<T> {
		return await this.request('DELETE', endpoint, query);
	}

	/**
	 * Fetches server status. Calls the /ping endpoint.
	 * @rejects: If the HTTP request fails.
	 * @returns: `true` if the server is online, rejects otherwise.
	 */
	public async fetchServerStatus(): Promise<boolean> {
		const pingResponse: StatusResponse | Error = await this.get<StatusResponse>('ping').catch(ex => ex);
		
		if (pingResponse instanceof Error)
			throw pingResponse;

		return true;
	}

	/**
	 * Fetches the TE listing's turnip code.
	 * @rejects: If the HTTP request fails.
	 * @returns: The turnip code if successful, rejects otherwise.
	 */
	public async fetchTurnipCode(): Promise<string> {
		type TurnipCodeResponse = StatusResponse & { turnipCode: string };
		
		const turnipCodeResponse: TurnipCodeResponse | Error = await this.get<TurnipCodeResponse>('getTurnipCode').catch(ex => ex);
		
		if (turnipCodeResponse instanceof Error)
			throw turnipCodeResponse;

		return turnipCodeResponse.turnipCode;
	}

	/**
	 * Opens the default island listing given the Dodo code.
	 * @rejects: If the HTTP request fails.
	 * @returns: `true` if successful, `false` otherwise.
	 */
	public async openListing(dodoCode: string): Promise<boolean> {
		if (!dodoCode)
			throw new Error('`dodoCode` cannot be undefined.');

		const openListingResponse: StatusResponse | Error = await this.post<StatusResponse>('createDefaultIslandListing', `dodoCode=${dodoCode}`).catch(ex => ex);
		
		if (openListingResponse instanceof Error)
			throw openListingResponse;

		return openListingResponse.status === 'okay';
	}

	/**
	 * Deletes the currently opened island listing.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async deleteIslandListing(): Promise<boolean> {
		const deleteIslandResponse: StatusResponse | Error = await this.delete<StatusResponse>('deleteIslandListing').catch(err => err);

		if (deleteIslandResponse instanceof Error)
			throw deleteIslandResponse;

		return deleteIslandResponse.status === 'okay';
	}

	/**
	 * Sets the currently opened island listing's locked state.
	 * @rejects: If the HTTP request fails.
	 * @returns: `true` if successful, `false` otherwise.
	 */
	public async setIslandLockedState(locked: boolean): Promise<boolean> {
		const setIslandLockedStateResponse: StatusResponse | Error = await this.patch<StatusResponse>('setIslandLockedState', `locked=${locked}`).catch(ex => ex);
		
		if (setIslandLockedStateResponse instanceof Error)
			throw setIslandLockedStateResponse;

		return setIslandLockedStateResponse.status === 'okay';
	}

	/**
	 * Sets the currently opened island listing's private state.
	 * @rejects: If the HTTP request fails.
	 * @returns: `true` if successful, `false` otherwise.
	 */
	public async setIslandPrivateState(_private: boolean): Promise<boolean> {
		const setIslandPrivateStateResponse: StatusResponse | Error = await this.patch<StatusResponse>('setIslandPrivateState', `private=${_private}`).catch(ex => ex);
		
		if (setIslandPrivateStateResponse instanceof Error)
			throw setIslandPrivateStateResponse;

		return setIslandPrivateStateResponse.status === 'okay';
	}

	/**
	 * Fetches the currently opened listing's details.
	 * @rejects: If the HTTP request fails.
	 * @returns: An object containing all of the currently opened listing's details.
	 */
	public async fetchListingDetails(): Promise<any> {
		const fetchListingDetailsResponse: any | Error = await this.get<any>('getIslandListingInfo').catch(ex => ex);

		if (fetchListingDetailsResponse instanceof Error)
			throw fetchListingDetailsResponse;

		return fetchListingDetailsResponse.islandInfo;
	}

	/**
	 * Fetches the currently opened listing's visitors.
	 * @rejects: If the HTTP request fails.
	 * @returns: An object containing the currently opened listing's visitors.
	 */
	public async fetchVisitors(): Promise<IslandVisitorInfo> {
		const fetchVisitorsResponse: any | Error = await this.get<any>('getVisitors').catch(ex => ex);
		
		if (fetchVisitorsResponse instanceof Error)
			throw fetchVisitorsResponse;

		return { visitors: fetchVisitorsResponse.visitors, total: fetchVisitorsResponse.total };
	}

	/**
	 * Fetches the auto-kick details (enabled/disabled & threshold).
	 * @rejects: If the HTTP request fails.
	 * @returns: An object containing auto-kick details.
	 */
	public async fetchAutoKickDetails(): Promise<AutoKickDetails> {
		const fetchAutoKickStatus: {autoKickEnabled: boolean} | Error = await this.get<{autoKickEnabled: boolean}>('getAutoKickEnabled').catch(ex => ex);
		
		if (fetchAutoKickStatus instanceof Error)
			throw fetchAutoKickStatus;

		const fetchAutoKickThreshold: {threshold: number} | Error = await this.get<{threshold: number}>('getAutoKickThreshold').catch(ex => ex);

		if (fetchAutoKickThreshold instanceof Error)
			throw fetchAutoKickThreshold;

		return {
			enabled: fetchAutoKickStatus.autoKickEnabled,
			threshold: fetchAutoKickThreshold.threshold
		};
	}

	/**
	 * Sets the Auto-Kick service enabled state.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async setAutoKickEnabled(enabled: boolean): Promise<boolean> {
		const autoKickStateResponse: StatusResponse | Error = await this.patch<StatusResponse>('setAutoKickEnabled', `enabled=${enabled}`);
		
		if (autoKickStateResponse instanceof Error)
			throw autoKickStateResponse;

		return autoKickStateResponse.status === 'okay';
	}

	/**
	 * Updates the Auto-Kick service kick threshold in minutes.
	 * @param threshold: The number of **minutes** before a visitor gets kicked.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async updateAutoKickThreshold(threshold: number): Promise<boolean> {
		const updateAutoKickThreshold: StatusResponse | Error = await this.patch<StatusResponse>('setAutoKickThreshold', `threshold=${threshold}`).catch(ex => ex);
		
		if (updateAutoKickThreshold instanceof Error)
			throw updateAutoKickThreshold;

		return updateAutoKickThreshold.status === 'okay';
	}

	/**
	 * Updates the currently opened island listing's maximum concurrent visitors.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async updateMaxVisitors(maxVisitors: number): Promise<boolean> {
		const updateMaxVisitorsResponse: StatusResponse | Error = await this.patch<StatusResponse>('updateMaxVisitors', `maxVisitors=${maxVisitors}`).catch(ex => ex);

		if (updateMaxVisitorsResponse instanceof Error)
			throw updateMaxVisitorsResponse;

		return updateMaxVisitorsResponse.status === 'okay';
	}

	/**
	 * Updates the currently opened island listing's maximum visitors that can join the queue.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async updateMaxLength(maxLength: number): Promise<boolean> {
		const updateMaxLengthResponse: StatusResponse | Error = await this.patch<StatusResponse>('updateMaxLength', `maxLength=${maxLength}`).catch(ex => ex);

		if (updateMaxLengthResponse instanceof Error)
			throw updateMaxLengthResponse;

		return updateMaxLengthResponse.status === 'okay';
	}

	/**
	 * Updates the currently opened island listing's turnip price.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async updateTurnipPrice(turnipPrice: number): Promise<boolean> {
		const updateMaxLengthResponse: StatusResponse | Error = await this.patch<StatusResponse>('updateTurnipPrice', `turnipPrice=${turnipPrice}`).catch(ex => ex);

		if (updateMaxLengthResponse instanceof Error)
			throw updateMaxLengthResponse;

		return updateMaxLengthResponse.status === 'okay';
	}

	/**
	 * Updates the currently opened island listing's description.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async updateDescription(description: string): Promise<boolean> {
		const updateDescriptionResponse: StatusResponse | Error = await this.patch<StatusResponse>('updateDescription', `description=${encodeURIComponent(description)}`).catch(ex => ex);

		if (updateDescriptionResponse instanceof Error)
			throw updateDescriptionResponse;

		return updateDescriptionResponse.status === 'okay';
	}

	/**
	 * Updates the currently opened island listing's Dodo code.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async updateDodoCode(dodoCode: string): Promise<boolean> {
		const updateDodoCodeResponse: StatusResponse | Error = await this.patch<StatusResponse>('updateDodoCode', `dodoCode=${dodoCode}`).catch(ex => ex);

		if (updateDodoCodeResponse instanceof Error)
			throw updateDodoCodeResponse;

		return updateDodoCodeResponse.status === 'okay';
	}

	/**
	 * Overrides the currently opened listing on TE Forger with the supplied x-island-id.
	 * @param islandID: The x-island-id header value.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async manualIslandOverride(islandID: string): Promise<boolean> {
		const manualIslandOverrideResponse: StatusResponse | Error = await this.post<StatusResponse>('manualIslandListingOverride', `islandID=${islandID}`).catch(ex => ex);

		if (manualIslandOverrideResponse instanceof Error)
			throw manualIslandOverrideResponse;

		return manualIslandOverrideResponse.status === 'okay';
	}

	/**
	 * Updates the currently opened island listing's Dodo code.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async kickVisitor(visitorID: string): Promise<boolean> {
		const kickVisitorResponse: StatusResponse | Error = await this.delete<StatusResponse>('kickVisitor', `visitorID=${visitorID}`).catch(ex => ex);

		if (kickVisitorResponse instanceof Error)
			throw kickVisitorResponse;

		return kickVisitorResponse.status === 'okay';
	}

	/**
	 * Sends a message to every visitor queued on the island listing.
	 * @rejects: If the HTTP request fails.
	 * @returns `true` if successful, `false` otherwise.
	 */
	public async sendMessage(message: string): Promise<boolean> {
		const sendMessageResponse: StatusResponse | Error = await this.post<StatusResponse>('sendMessage', `message=${message}`).catch(ex => ex);

		if (sendMessageResponse instanceof Error)
			throw sendMessageResponse;

		return sendMessageResponse.status === 'okay';
	}
}

/**
 * Describes a "status response", a response that communicates whether the request was completed successfully or not.
 */
export interface StatusResponse {
	/**
	 * Whether the request was successful or not.
	 */
	status: 'okay' | 'error',

	/**
	 * The optional message sent by the server.
	 */
	message?: string
};

/**
 * Describes the current status of the auto-kick service.
 */
export interface AutoKickDetails {
	/**
	 * Whether the auto-kick is enabled or not.
	 */
	enabled: boolean;

	/**
	 * The threshold, in minutes, after which a user is automatically kicked from the island listing queue.
	 */
	threshold: number;
}

export default new API();
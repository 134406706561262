import { selector } from "recoil";
import API, { AuthenticationStatus, UserGroup } from "../../../services/AuthenticationAPI";

/**
 * The user's logged in state.
 */
export const userLoggedInState = selector<AuthenticationStatus | undefined>({
	key: 'userLoggedInState',
	get: async ({get}) => {
		const response = await API.fetchAuthStatus().catch((ex: Error) => ex);
		return response instanceof Error ? undefined : response;
	}
});

/**
 * The user's group.
 */
export const userGroupState = selector<UserGroup | undefined>({
	key: 'userGroupState',
	get: async ({get}) => {
		const response = await API.fetchUserGroup().catch((ex: Error) => ex);
		return response instanceof Error ? undefined : response;
	}
});

/**
 * Determines whether the user is a mod or not.
 */
export const isUserModState = selector<boolean>({
	key: 'isUserAdminState',
	get: async ({get}) => {
		const userGroup: UserGroup | undefined = get(userGroupState);

		return userGroup?.isMod ?? false;
	}
});
import { useState } from 'react';
import API from '../API';

export function ManualOverrideSection(): JSX.Element {
	const [manualOverrideVisible, setManualOverrideVisible] = useState<boolean>(false);
	const [xIslandID, setXIslandID] = useState<string | null>(null);

	const btnManualOverrideOnClick = async () => {
		if (!xIslandID)
			return alert('The x-island-id parameter must be provided.');

		const result = await API.manualIslandOverride(xIslandID).catch(ex => ex);

		if (result instanceof Error)
			return alert(`There was an error during manual override: ${result.message}`);

		if (result)
			alert('Island listing manually overridden successfully.');
		else
			alert('Manual override did not complete successfully.');

		window.location.reload();
	};
	
	return (
		<div className={`manualOverride ${manualOverrideVisible ? 'manualOverrideVisible' : 'manualOverrideHidden'}`}>
			<h1 onClick={ () => { setManualOverrideVisible(!manualOverrideVisible) } }>Manual Override</h1>
			<h4>(allows to work around TE's anti-botting system)</h4>
			<label>x-island-id Header: </label>
			<input type="text" onChange={ e => setXIslandID(e.target.value) } />
			<div className="button" id="btnManualOverride" onClick={ btnManualOverrideOnClick }>Manual Override</div>
		</div>
	);
}
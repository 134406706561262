import './App.scss';
import { Outlet } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { NavigationBar } from './components/NavigationBar';
import { Suspense } from 'react';

function App() {
	return (
		<RecoilRoot>
			<div className="App">
				<Suspense fallback={<div>Loading...</div>}>
					<NavigationBar />
				</Suspense>
				<div className='main-outlet'>
					<Outlet />
				</div>
			</div>
		</RecoilRoot>
	);
}

export default App;

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './shared.scss';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Home from './pages/home/HomePage';
import AdminPage from './pages/admin/AdminPage';
import NotFoundPage from './pages/errors/404.NotFoundPage';
import Redirect from './pages/redirect/Redirect';
import { TEAdminPage } from './pages/admin/te-admin/TEAdminPage';
import { SetupManagerPage } from './pages/admin/setup-manager/SetupManagerPage';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Suspense><AdminPage /></Suspense>} />
          <Route path="/te-admin" element={<Suspense><TEAdminPage /></Suspense>} />
          <Route path="/redirect" element={<Suspense><Redirect /></Suspense>}/>
          <Route path="/stream-setup" element={<Suspense><SetupManagerPage /></Suspense>}></Route>
          <Route  path="*" element={<NotFoundPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

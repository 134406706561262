import { useRecoilValue } from 'recoil';
import { isUserModState } from '../pages/admin/state/te-admin.recoil';
import NavBar from './generic-navBar/NavigationBar';
import NavigationBarItem from './generic-navBar/NavigationBarItem';

export function NavigationBar(): JSX.Element {
	const isUserMod: boolean = useRecoilValue(isUserModState);

	return (
		<NavBar>
			<NavigationBarItem url='/'>Home</NavigationBarItem>
			{isUserMod && <NavigationBarItem url='/stream/queue/admin'>Queue Admin</NavigationBarItem>}
			{isUserMod && <NavigationBarItem url='/TE-admin'>TE Admin</NavigationBarItem>}
			{isUserMod && <NavigationBarItem url='/setup-manager'>Stream Setup Manager</NavigationBarItem>}
		</NavBar>
	);
}
import React, { Suspense, useState } from 'react';
import { useRecoilValue } from 'recoil';
import API, { AutoKickDetails } from '../API';
import { Bauble } from '../components/Bauble';
import { IslandListingDetails } from '../model/IslandListingDetails';
import { IslandVisitorInfo } from '../model/IslandListingVisitors';
import { autoKickDetailsState, listingDetailsState, turnipCodeState, visitorInfoState } from '../state/recoil';

function TurnipExchangeLink() {
	const turnipCode = useRecoilValue(turnipCodeState);

	return (
		<a id="turnipExchangeLink" href={`https://turnip.exchange/island/${turnipCode}`} target="_blank" rel="noreferrer">
			<h2 id="turnipCode">({turnipCode ?? 'none'})</h2>
		</a>
	);
}

function Baubles() {
	const listingDetails: IslandListingDetails | undefined = useRecoilValue(listingDetailsState);
	const autoKickDetails: AutoKickDetails | undefined = useRecoilValue(autoKickDetailsState);

	const mapStatus = (property: number | undefined) => {
		if (property === undefined)
			return 'inactive';

		return property === 1 ? 'on' : 'off';
	};

	const autoKickStatus: any = listingDetails === undefined ? undefined : autoKickDetails?.enabled ? 1 : 0;

	return (
		<div className="baubles flex-row">
			<Bauble status={listingDetails !== undefined ? 'on' : 'off'}>Island {listingDetails !== undefined ? 'Open' : 'Closed'}</Bauble>
			<Bauble status={mapStatus(listingDetails?.locked)}>Locked</Bauble>
			<Bauble status={mapStatus(listingDetails?.private)}>Private</Bauble>
			<Bauble status={mapStatus(autoKickStatus)}>Auto-Kick: {autoKickDetails?.enabled ? autoKickDetails.threshold + ' minutes' : 'OFF'}</Bauble>
		</div>
	);
}

function Visitor(props: { visitorID: string } & React.PropsWithChildren): JSX.Element {
	return (
		<li data-visitorid={props.visitorID}>
			{props.children}
		</li>
	);
}

export function ListingInfoSection() {
	const listingDetails: IslandListingDetails | undefined 	= useRecoilValue(listingDetailsState);
	const visitorInfo: IslandVisitorInfo | undefined 		= useRecoilValue(visitorInfoState);
	const [listingInfoVisible, setListingInfoVisible] 		= useState<boolean>(true);

	const btnRefreshClicked = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();

		window.location.reload();
	};

	const btnKickClicked = async (e: React.MouseEvent<HTMLElement>) => {
		const visitorId = (e.target as HTMLElement).parentElement?.getAttribute('data-visitorid');

		if (!visitorId)
			return alert('Could not kick the specified visitor, visitorID hidden field missing.');

		const result: boolean 					= await API.kickVisitor(visitorId).catch(() => {}) ?? false;
		const visitorName: string | undefined 	= (e.target as HTMLElement)
			.parentElement
			?.querySelector('.info')
			?.textContent
			?.match(/\d+:\s(.*?)\s\(/)?.[1];

		if (result)
			alert(`Visitor ${visitorName} [${visitorId}] was kicked from the queue.`);
		else
			alert(`Visitor ${visitorName} [${visitorId}] could not be kicked from the queue.`);

		window.location.reload();
	};

	return (
		<div className={`listingInfo ${listingInfoVisible ? 'listingInfoVisible' : 'listingInfoHidden'}`}>
			<div className="listingInfoHeader" onClick={() => {setListingInfoVisible(!listingInfoVisible)}}>
				<h1>TE Island Info </h1>
				<Suspense>
					<TurnipExchangeLink />
				</Suspense>
				<div className="button" id="btnRefresh" onClick={btnRefreshClicked}>Refresh</div>
			</div>

			<Suspense fallback={<div>Loading...</div>}>
				<Baubles />
			</Suspense>

			{(listingDetails !== undefined) && <>
				<div className="description">
					<h3>Description</h3><span id="textDescription">{listingDetails?.description ?? 'Default description'}</span>
				</div>

				<div className="properties">
					<h3>Queue Properties</h3>
					<div className="length">
						<span>(</span>
						<span id="inQueue">{visitorInfo?.total ?? -1}</span>
						<span>/</span>
						<span id="maxLength">{listingDetails?.maxQueue ?? -1}</span>
						<span>, </span>
						<span id="maxVisitors">{listingDetails?.visitorLimit ?? -1}</span>
						<span> visitor(s) at a time)</span>
					</div>
					<div className="turnipsPriceWrapper">
						<span>Turnips price: </span>
						<span id="turnipsPrice">{listingDetails?.turnipPrice ?? 123}</span>
						<span> bells</span>
					</div>
					<div className="dodoCode">
						<span className="dodoCodeTitle">Dodo Code: </span>
						<span id="dodoCode">{listingDetails?.dodoCode ?? 'ABCDE'}</span>
					</div>
				</div>

				<div className="visitors">
					<h3>Visitors</h3>
					<ul id="visitors">
						{
							visitorInfo?.visitors?.map<JSX.Element>(visitor => {
								return <Visitor key={visitor.$id.toString()} visitorID={visitor.$id.toString()}>
									<span className="kick" onClick={btnKickClicked}>X</span>
									<span className="info">{visitor.place}: {visitor.name} ({visitor.time} minute{visitor.time > 1 ? 's' : ''})</span>
								</Visitor>
							})
						}
					</ul>
				</div>
			</>}
		</div>
	)};